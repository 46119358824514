import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import 'bootstrap/dist/css/bootstrap.css';
import reportWebVitals from './reportWebVitals';
import { PublicClientApplication } from '@azure/msal-browser';
import { msalConfig } from './authConfig';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
// import SwDev from './SwDev';
import { PersistGate } from 'reduxjs-toolkit-persist/integration/react';
import store from './app/store';
import { Provider } from 'react-redux';
import { persistStore } from 'redux-persist';
import { datadogLogs } from '@datadog/browser-logs'
import { datadogRum } from '@datadog/browser-rum';
import Package from '../package.json';
import appSettings from './appsettings.json';
import { ReactQueryProvider } from './components/react-query-provider';

export const msalInstance = new PublicClientApplication(msalConfig);
let persistor = persistStore(store);
ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <ReactQueryProvider>
                <PersistGate loading={null} persistor={persistor}>
                    <App msalInstance={msalInstance} />
                </PersistGate>
            </ReactQueryProvider>
        </Provider>
    </React.StrictMode>,
    document.getElementById('root')
);
//SwDev();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

datadogLogs.init({
    clientToken: 'pub1a6bb1ec6177b7c262331b5152d057d2',
    site: 'us3.datadoghq.com',
    forwardErrorsToLogs: true,
    sessionSampleRate: 100,
    service: 'memberportal',
    version: Package.version,
    env: appSettings.environment,
    telemetrySampleRate: 100
  });
  
  datadogRum.init({
    applicationId: 'fab731c8-079f-43ca-9509-9889709fb04d',
    clientToken: 'pub1a6bb1ec6177b7c262331b5152d057d2',
    site: 'us3.datadoghq.com',
    service: 'memberportal',
    version: Package.version,
    env: appSettings.environment,
    allowedTracingUrls: [(url) => url.startsWith(appSettings.constants.appBaseUrl)],
    traceSampleRate: appSettings.datadog.traceSampleRate,
    // Specify a version number to identify th.e deployed version of your application in Datadog
    // version: '1.0.0',
    sessionSampleRate: appSettings.datadog.sessionSampleRate,
    sessionReplaySampleRate: appSettings.datadog.sessionReplaySampleRate,
    telemetrySampleRate: appSettings.datadog.telemetrySampleRate,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input'
  });
  
  datadogRum.startSessionReplayRecording();

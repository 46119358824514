import React from 'react';
import { LabFile } from './LabFile';
import { Row } from 'reactstrap';

export const LabFilesList = ({ fileNames = [] }) => {
    const showEnumeration = Boolean(fileNames.length > 1);
    return !Array.isArray(fileNames) ? null : fileNames.map( (fileName, key) => (
        <Row key={key}>
            <LabFile fileName={fileName} enumeration={showEnumeration && key + 1} />
        </Row>
    ));
};

export default LabFilesList;
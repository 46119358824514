/**
 * @author RamaTulasi <rama.tulasi@sollishealth.com>
 */
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import {
    Col,
    Container,
    Form,
    FormGroup,
    Label,
    Nav,
    NavItem,
    NavLink,
    Row,
    TabContent,
    TabPane,
} from 'reactstrap';
import BreadCrumb from '../../components/BreadCrumb';
import FooterCallUs from '../../components/FooterCallUs';
import {
    deleteMessage,
    getAppUserMessageList,
    getAllConversationList,
    GetUnReadMessageCount,
} from '../../services/RestApi';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
    storeDeleteMessages,
    storeInboxMessages,
    storeSentMessages,
} from '../../features/messagesSlice';
import { useOnlineStatus } from '../../utilities/useOnlineStatus';
import { SYSTEM_ERROR_PAGE } from '../../utilities/Constants';
import { checkVersionDiff } from '../../utilities/helper';
import parse from 'html-react-parser';
import { useImpersonation, CurrentMemberAvatar } from '../../components';
import { AttachmentsIndicator } from './attachment-indicator';

function Messages() {
    const { isImpersonating } = useImpersonation();
    const isOnline = useOnlineStatus(); //network connection status
    const { typeid } = useParams();
    const dispatch = useDispatch();
    const _messages = useSelector((state) => state.messages);
    const _memberDetails = useSelector((state) => state.memberDetails);
    const [search, setSearch] = useState('');
    const [searchResults, setSearchResults] = useState();
    const [messageList, SetMessageList] = useState();
    const [error, setError] = useState();
    const [loading, setLoading] = useState(false);
    const [selectedMembers, setSelectedMembers] = useState([]);
    const [checkbox1, setCheckbox1] = useState([]);
    const [activeTab, setActiveTab] = useState(typeid);
    const [inboxCount, setInboxCount] = useState(0);
    const [selectedAll, setSelectedAll] = useState(false);
    const todayDate = moment().format('l');
    const yesterdayDate = moment()
        .subtract(1, 'days')
        .startOf('day')
        .format('l');

    const type = {
        1: 'Inbox',
        2: 'Sent',
        3: 'Deleted',
    };
    const history = useHistory();

    //To set active tab and get message list from api
    const toggle = (tab) => {
        if (activeTab !== tab) {
            setSearch('');
            setSelectedAll(false);
            SetMessageList();
            setSearchResults();
            setCheckbox1([]);
            setActiveTab(tab);
            if (isOnline) {
                getList(selectedMembers, type[tab]);
            } else {
                SetMessageList(_messages[type[tab]]);
                setSearchResults(_messages[type[tab]]);
            }
        }
    };

    useEffect(() => {
        let messageData = {
            params: {
                    status: type[activeTab],
                },
        };
        GetUnReadMessageCount(messageData)
            .then((res) => {
                checkVersionDiff(dispatch, res);
                res.data?.count
                    ? setInboxCount(res.data.count)
                    : setInboxCount();
            })
            .catch(() => {});
        let temp = [];
        temp.push({ memberId: _memberDetails.loginMember[0].memberID });
        _memberDetails?.dependents?.map((it) => {
            if (it.selected === true) {
                temp.push({ memberId: it.memberID });
            }
        });
        setSelectedMembers(temp);
        if (isOnline) {
            getList(temp, type[activeTab]);
        } else {
            SetMessageList(_messages[type[activeTab]]);
            setSearchResults(_messages[type[activeTab]]);
        }

        // isToday('2022-06-14T12:59:15');
        // isToday('2022-06-16T08:18:36');
        // isToday('2022-06-15T17:21:35');
        // isYesterday();
    }, [_memberDetails]);

    //To get message list from api
    const getList = (selectedMembers, type) => {
        setLoading(true);
        let payload = {
            params: {
                status: type, //Sent/Deleted/Inbox
            }
        };
        getAllConversationList(payload).then((conversations) => {
            checkVersionDiff(dispatch, conversations);
            getAppUserMessageList(payload)
            .then((res) => {
                checkVersionDiff(dispatch, res);
                let conversationsData = conversations.data;
                let data = res.data.map(
                    (it) => {
                        let header = '';
                        conversationsData.map((con) => {
                            if(con.conversationId === it.conversationId)
                            {
                                header = con.header;
                            }
                        })
                        return { ...it, checked: false, header };
                    }
                );
                data = data.reverse();
                SetMessageList(data);
                setSearchResults(data);
                type === 'Inbox'
                    ? dispatch(storeInboxMessages(data))
                    : type === 'Sent'
                    ? dispatch(storeSentMessages(data))
                    : dispatch(storeDeleteMessages(data));
                // console.table(data);
            })
            .catch((err) => {
                console.log(err.data);
                // if (type === 'Inbox') {
                //     setInboxCount(0);
                // }
                setError(`No ${type === 'Inbox' ? 'Messages' : type?.toLowerCase() + ' messages'}`);
            })
            .finally(() => setLoading(false));
        })
        .catch((err) => {
            console.log(err.data);
            // if (type === 'Inbox') {
            //     setInboxCount(0);
            // }
            setError(`No ${type === 'Inbox' ? 'Conversations' : type?.toLowerCase() + ' conversations'}`);
        })
        .finally(() => setLoading(false));
        
    };

    //it takes date and checks is it today or not
    const isToday = (date) => {
        const msgDate = moment(date).local().format('l');
        return moment(todayDate).isSame(msgDate);
    };
    //it takes date and checks is it yesterday or not
    const isYesterday = (date) => {
        const msgDate = moment(date).local().format('l');
        return moment(yesterdayDate).isSame(msgDate);
    };

    //it checks date
    const checkDate = (date) => {
        return isToday(date)
            ? 'Today'
            : isYesterday(date)
            ? 'Yesterday'
            : moment(date).local().format('l');
    };

    //it updates the message checkbox
    const addCheckedList = (e, index) => {
        const { value, checked } = e.target;
        const tempSearchResult = searchResults.map( (f, i) => {
            if(i===index) {
               return {...f, checked: !f.checked}
            }
            return f;
        });
        setSearchResults(tempSearchResult);
        checked
            ? setCheckbox1([...checkbox1, value])
            : setCheckbox1(checkbox1.filter((e) => e !== value));
        // console.log(messageList);
    };

    //To select all messages
    const selectAll = (e) => {
        setSelectedAll(e.target.checked);
        let temp = searchResults.map((it) => ({
            ...it,
            checked: e.target.checked,
        }));
        setSearchResults(temp);
        if (temp.length === messageList.length) SetMessageList(temp);
    };

    //delete messages from data base
    const handleDelete = () => {
        setLoading(true);
        setSelectedAll(false);
        let temp = [];
        // messageList.map((it) => {
        searchResults.map((it) => {
            if (it.checked === true) temp.push(it.messageHeaderId);
        });
        if (!temp.length > 0) {
            return console.log('nothing is selected');
        }
        let payload = {
            messageheaderIds: temp,
            deleteRemarks: 'Testing',
            user: _memberDetails.loginMember[0].memberID,
        };
        deleteMessage(payload)
            .then((res) => {
                checkVersionDiff(dispatch, res);
                if (res.data.success) {
                    // alert(res.data.message);
                    getList(selectedMembers, type[activeTab]);
                    SetMessageList(
                        messageList?.filter(
                            (it) => !temp.includes(it.messageHeaderId)
                        )
                    );
                    setSearchResults(
                        messageList?.filter(
                            (it) => !temp.includes(it.messageHeaderId)
                        )
                    );
                    setSearch('');
                }
            })
            .catch((err) => history.push(SYSTEM_ERROR_PAGE))
            .finally(() => setLoading(false));
    };

    //To filter messages as per search bar value
    const searchHandler = (e) => {
        setSearch(e.target.value);
        if (e.target.value !== '') {
            const newMsgList = messageList.filter((msg) => {
                return (
                    msg?.content +
                    ' ' +
                    moment(msg?.sentDate).local().format('l') +
                    ' ' +
                    msg?.header
                )
                    .toLowerCase()
                    .includes(e.target.value.toLowerCase());
            });
            console.log(newMsgList);
            setSearchResults(newMsgList);
            if (newMsgList.length > 0) setError('No messages found');
        } else setSearchResults(messageList);
    };
    let isHTML = RegExp.prototype.test.bind(/(<([^>]+)>)/i);
    return (
        <Container className="messages margin-top-large padding-bottom">
            {/*Section Heading*/}
            <BreadCrumb subTitle="Messages" />
            <Row className="bg-white">
                <Col>
                    <h4 className="header-text text-center">Messages</h4>
                </Col>
            </Row>
            {/* Inbox/Sent/Deleted */}
            <Row className="bg-white">
                <Nav tabs className="sollis-tabs">
                    <NavItem>
                        <NavLink
                            className={activeTab === '1' ? 'active' : ''}
                            onClick={() => {
                                toggle('1');
                            }}
                        >
                            <i className="fa-light fa-envelope" />
                            <p>
                                Inbox {inboxCount > 0 ? `(${inboxCount})` : ''}
                            </p>
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={activeTab === '2' ? 'active' : ''}
                            onClick={() => {
                                toggle('2');
                            }}
                        >
                            <i className="fa-light fa-paper-plane-top" />
                            <p>Sent</p>
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={activeTab === '3' ? 'active' : ''}
                            onClick={() => {
                                toggle('3');
                            }}
                        >
                            <i className="fa-light fa-trash-can" />
                            <p>Deleted</p>
                        </NavLink>
                    </NavItem>
                </Nav>
            </Row>
            <TabContent activeTab={activeTab}>
                <TabPane tabId="1">
                    {/*Search bar*/}
                    {messageList?.length > 0 && (
                        <Row className="search-bar">
                            {' '}
                            <Col lg="12">
                                <Row>
                                    <Col lg="6" className="mx-auto">
                                        <div className="search">
                                            <input
                                                type="text"
                                                placeholder="Search"
                                                value={search}
                                                maxLength="750"
                                                onChange={(e) =>
                                                    searchHandler(e)
                                                }
                                            />
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    )}
                    {/*Compose Message*/}
                    <Row className="compose-message">
                        {' '}
                        <Col className="button-group">
                            {/* {messageList?.length > 0 &&
                            searchResults?.find((it) => it.checked === true) &&
                            isOnline ? (
                                <div
                                    onClick={handleDelete}
                                    className="button-action btn-border"
                                >
                                    delete
                                </div>
                            ) : (
                                messageList?.length > 0 && (
                                    <div className="button-action btn-disable">
                                        delete
                                    </div>
                                )
                            )} */}

                            {!loading && !isImpersonating && (
                                <Link
                                    to="/composeMail"
                                    className={`button-action btn-light ${
                                        isOnline ? '' : 'btn-disable'
                                    }`}
                                    disabled={isImpersonating}
                                >
                                    <i className="fa-light fa-pencil" /> &nbsp;
                                    compose
                                </Link>
                            )}
                        </Col>
                    </Row>
                    {Table()}
                </TabPane>
                <TabPane tabId="2">
                    {/*Search bar*/}
                    {messageList?.length > 0 && (
                        <Row className="search-bar">
                            {' '}
                            <Col lg="12">
                                <Row>
                                    <Col lg="6" className="mx-auto">
                                        <div className="search">
                                            <input
                                                type="text"
                                                placeholder="Search"
                                                value={search}
                                                maxLength="750"
                                                onChange={(e) =>
                                                    searchHandler(e)
                                                }
                                            />
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    )}
                    {/*Delete Message Btn*/}
                    {/* {messageList?.length > 0 && (
                        <Row className="compose-message">
                            {' '}
                            <Col className="button-group">
                                {searchResults?.find(
                                    (it) => it.checked === true
                                ) && isOnline ? (
                                    <div
                                        onClick={handleDelete}
                                        className="button-action btn-border"
                                    >
                                        delete
                                    </div>
                                ) : (
                                    <div className="button-action btn-disable">
                                        delete
                                    </div>
                                )}
                            </Col>
                        </Row>
                    )} */}
                    {Table()}
                </TabPane>
                <TabPane tabId="3">
                    {/*Search bar*/}
                    {messageList?.length > 0 && (
                        <Row className="search-bar">
                            {' '}
                            <Col lg="12">
                                <Row>
                                    <Col lg="6" className="mx-auto">
                                        <div className="search">
                                            <input
                                                type="text"
                                                placeholder="Search"
                                                value={search}
                                                maxLength="750"
                                                onChange={(e) =>
                                                    searchHandler(e)
                                                }
                                            />
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    )}
                    {/*Trash Message*/}
                    <Row className="trash-msg bg-white">
                        {' '}
                        <Col>
                            <p>
                                Messages that have been in Trash more than 60
                                days will be automatically deleted.
                            </p>
                        </Col>
                    </Row>
                    {Table()}
                </TabPane>
            </TabContent>
            <FooterCallUs />
        </Container>
    );
    function Table() {
        console.log('messages:', searchResults)
        return (
            <Row className="message-list bg-white" style={{marginTop: activeTab === '2' ?  '20px' : ''}}>
                <Col lg="12">
                    {' '}
                    {/*Message List*/}
                    
                    <Form>
                        {loading ? (
                            <div className="working appointmentsloader mt-3"></div>
                        ) : messageList?.length > 0 &&
                          searchResults?.length > 0 ? (
                            searchResults?.map((it, index) => (
                                <FormGroup check className="message-section">
                                    <Label>
                                        <div className="float-left">
                                            {/* {activeTab !== '3' && (
                                                <Input
                                                    type="checkbox"
                                                    name="msg"
                                                    value={it.messageId}
                                                    onChange={(e) =>
                                                        addCheckedList(e, index)
                                                    }
                                                    defaultChecked={it.checked}
                                                    checked={it.checked}
                                                />
                                            )} */}
                                            <CurrentMemberAvatar
                                                className='imgBg'
                                                size='27'
                                                round={true}
                                            />
                                        </div>
                                        <Link
                                            to={`/viewmessage/${activeTab}/${it.conversationId}/${it.messageId}`}
                                        >
                                            <div className="msg float-right">
                                                <p className={it.seenByMember === false? "bold" : "semi-bold"}>
                                                    <span
                                                        className={`msg-subject ${
                                                            it.seenByMember === false &&
                                                            activeTab === '1'
                                                                ? 'unread'
                                                                : 'read'
                                                        }`}
                                                    >
                                                        {it.header}{' '}
                                                    </span>
                                                    <AttachmentsIndicator message={it} />
                                                    <span className="date">
                                                        {' '}
                                                        {checkDate(
                                                            it.sentDate
                                                        )}{' '}
                                                        {''}{' '}
                                                        <Link
                                                            to={`/viewmessage/${activeTab}/${it.conversationId}/${it.messageId}`}
                                                        >
                                                            <i className="fa-light fa-chevron-right" />
                                                        </Link>
                                                    </span>{' '}
                                                    <p className="msg-txt">
                                                        { !isHTML(it.content) ? it.content : parse(it.content)}
                                                    </p>
                                                </p>
                                            </div>
                                        </Link>
                                    </Label>
                                </FormGroup>
                            ))
                        ) : (
                            <p className="mt-3 err-msg">{error}</p>
                        )}
                    </Form>
                </Col>
            </Row>
        );
    }
}



export default Messages;

/**
 * @author RamaTulasi <rama.tulasi@sollishealth.com>
 */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useParams } from 'react-router-dom';
import { Modal, ModalFooter, ModalHeader, ModalBody } from 'reactstrap';
import {
    CardBody,
    Col,
    Collapse,
    Container,
    Form,
    FormGroup,
    Input,
    Label,
    Progress,
    Row,
} from 'reactstrap';
import BreadCrumb from '../../components/BreadCrumb';
import FooterCallUs from '../../components/FooterCallUs';
import {
    addNewAppointment,
    messageRequest,
    saveMessageContentRequest,
    deleteFile,
    uploadFile,
} from '../../services/RestApi';
import SelectMember from './SelectMember';
import {
    resetAppointmentDetails,
    storeAppointmentDetails,
    storeNotesDetails,
} from '../../features/appointmentDetailsSlice';
import { VACCINATION_GROUPID , SOMETHINGELSE_GROUPID, SYSTEM_ERROR_PAGE, LABSANDIMAGES_GROUPID } from '../../utilities/Constants';
import { checkVersionDiff } from '../../utilities/helper';
import { RichTextEditor } from 'sollishealth.core.react';

function NotSure() {
    const history = useHistory();
    const _appointmentTypesFL = useSelector(
        (state) => state.appointmentTypesFL.appointmentTypesFL
    );
    const _appointmentDetails = useSelector(
        (state) => state.appointmentDetails.appointmentDetails
    );
    const [isBtnEnabled, setIsBtnEnabled] = useState(false);
    const [collapse, isCollapse] = useState(false);
    const [errMsg, setErrMsg] = useState();
    const [loading, setLoading] = useState(false);
    const { pGroupId, groupId2, groupId } = useParams();
    const _memberStore = useSelector((state) => state.memberDetails);
    const members = [..._memberStore.loginMember, ..._memberStore?.dependents];

    const [selectedMember, setSelectedMember] = useState(members[0]);
    const [phoneNumber, setPhoneNumber] = useState(
        members[0].mobileNumber ||
        members[0].primaryPhoneNumber ||
        members[0].secondaryPhoneNumber ||
        '---'
    );
    const [progress, setProgress] = useState();
    const [photoList, setPhotoList] = useState();
    const [isCamClicked, setIsCamClicked] = useState(false);
    const [cameraImgList, setCameraImgList] = useState();
    const [notes, setNotes] = useState('');
    const [error, setError] = useState(false);
    const [showAptLoader, setShowAptLoader] = useState(false);
    const canSubmit = Boolean((notes || '').trim());
    const dispatch = useDispatch();

    const setNotesData = (val) => {
        setNotes(val);
        if(groupId2 === "14") {
            dispatch(storeNotesDetails(val));
        }
        
    }
    
    const headerTitle = _appointmentTypesFL[Number(pGroupId)]?.filter(
        (it) => it.groupId == groupId2
    )[0]?.groupName;

    //Selected member details
    const onSelectedChange = (e, data) => {
        setSelectedMember(data);
        setPhoneNumber(
            data.mobileNumber ||
            data.primaryPhoneNumber ||
            data.secondaryPhoneNumber ||
            '---'
        );
    };

    /*
     * To delete file from database
     */

    function deleteItem(data) {
        // alert('delete icon clicked');
        deleteFile(data.imageUrl)
            .then((res) => {
                checkVersionDiff(dispatch, res);
                setPhotoList();
                setCameraImgList();
            })
            .catch((err) => history.push(SYSTEM_ERROR_PAGE));
    }

    /*
     * To upload file to database
     */

    function uploadImage(event) {
        setIsBtnEnabled(true);
        event.target.id === 'camera'
            ? setIsCamClicked(true)
            : setIsCamClicked(false);
        setError(false);
        setErrMsg();
        const file = event.target.files[0];
        if (!file) {
            setIsBtnEnabled(false);
            return;
        }
        if (file.size > 2e7) {
            setError(true);
            setErrMsg('Please upload a file smaller than 20 MB');
            setIsBtnEnabled(false);
            return;
        }
        uploadFile(file, (event) => {
            setProgress(Math.round((100 * event.loaded) / event.total));
        })
            .then((res) => {
                checkVersionDiff(dispatch, res);
                setProgress();
                let result = {};
                result.fileName = res.data.fileName;
                result.imageUrl = res.data.imageUrl;
                result.refNumber = res.data.refNumber;
                event.target.id === 'camera'
                    ? setCameraImgList([result])
                    : setPhotoList([result]);
                event.target.value = null;
                setIsBtnEnabled(false);
            })
            .catch((err) => {
                setProgress();
                setError(true);
                setErrMsg('Invalid file');
                setIsBtnEnabled(false);
            });
    }

    /*
     * creating payload for api-addNewAppointment
     */

    const getPayload = () => {
        // let group = getGroupDetails();
        let covidNotes = null
        if (groupId2 == 9 || groupId2 == 15) {
            if (_appointmentDetails?.screeningQuestion?.answersDescription == 'Yes') covidNotes = 'Covid Screening - possible exposure or diagnosis,';
            else covidNotes = 'Covid Screening - passed,';
        }

        let appointmentRequestDetails = [
            {
                appointmentFor: selectedMember.memberUsername,
                phoneNumber: phoneNumber,
                notes: `${covidNotes} ${notes}`,
                memberID: selectedMember.memberID,
                attachment: photoList || cameraImgList,
            },
        ];

        let payload = {
            addAppointmentRequest: {
                appointmentgroupFirstLevelId: 3,
                memberID: _memberStore.loginMember[0]?.memberID,
                appointmentRequestDetails: JSON.stringify(
                    appointmentRequestDetails[0]
                ),
                appointmentForMemberIds: [
                    {
                        memberID: selectedMember.memberID,
                    },
                ],
                memberInfo: '',
                detailsForTask: '',
                createdUser: _memberStore.loginMember[0]?.memberUsername,
            },
        };
        return payload;
    };
    /*
     * Adding New Appointment
     */

    const getMessageDetails = () => {
        let covidNotes = _appointmentDetails?.screeningQuestion?.answersDescription === 'Yes' ? 'Covid Screening - possible exposure or diagnosis,' : 'Covid Screening - passed,';
        let type = groupId2 === LABSANDIMAGES_GROUPID ? 'In-Clinic, Labs and Imaging' : groupId2 === VACCINATION_GROUPID ? 'In-Clinic, Vaccination--Vaccination Other': 'In-Clinic, Something Else';
        if (groupId2 === LABSANDIMAGES_GROUPID){

        } 
        var today  = new Date();
        var options = { weekday: 'long', year: 'numeric', month: 'short', day: 'numeric' };
        var dateString = today.toLocaleDateString("en-US", options);
        var apptFor =  _memberStore.loginMember[0].memberFullname;
        let message = `Type: ${type}\r\nRequest Date: ${dateString}\r\nStatus: New\r\nFor Member: ${apptFor}\r\nAppointment For: ${apptFor}\r\n Member ID: ${selectedMember.memberID}\r\nPhone Number: ${phoneNumber}\r\nCovid Notes: ${covidNotes}\r\nNotes: ${notes}`;



        //let message = `Appointment For: ${appointmentRequestDetails[0].appointmentFor}\r\n Phone Number: ${appointmentRequestDetails[0].phoneNumber}\r\n Notes: ${appointmentRequestDetails[0].notes}\r\n Member ID:${appointmentRequestDetails[0].memberID}`;
        return message;
     };

    const createAppointment = () => {
        setShowAptLoader(true)
        setLoading(true);
        let message = getMessageDetails() + ' ';
        let payload = {
           externalId: 'Not Sure',
           header: `Appointment Request`,
           type: `Appointment Request`
       };
        setError('');
        messageRequest(payload)
           .then((res) => {
            checkVersionDiff(dispatch, res);
               const data = res.data;
               payload = {
                   conversationId: data.conversationId,
                   externalId: data.externalId,
                   content: message,
                   staffMemberSentBy:  _memberStore?.loginMember[0]?.memberFullname
               }
               saveMessageContentRequest(payload).then(() =>{
                checkVersionDiff(dispatch, res);
                setShowAptLoader(false)
                   history.push(`/Confirmation/${pGroupId}`);
               })
               .catch((e) => {
                setShowAptLoader(false)
                   //notify('Message not sent','error');
                   setError('Something went wrong!!');
                }); 
           })
           .catch((e) => {
            setShowAptLoader(false)
              //notify('Message not sent','error');
              setError('Something went wrong!!');
           })
           .finally(() => {
               setLoading(false);
           });
    };

    // const addAppointment = () => {
    //     setLoading(true);
    //     let payload = getPayload();
    //     let appointmentRequestDetails = {
    //         appointmentFor: selectedMember.memberUsername,
    //         phoneNumber: phoneNumber,
    //         notes: `${notes}`,
    //         memberID: selectedMember.memberID,
    //         attachment: photoList || cameraImgList,
    //     };
    //     setError(false);
    //     addNewAppointment(payload)
    //     .then((res) => {
    //         dispatch(storeAppointmentDetails(appointmentRequestDetails));
    //         history.push(`/Confirmation/${pGroupId}/${groupId2}`);
    //         })
    //         .catch((e) => {
    //             setLoading(false);
    //             setError(true);
    //             history.push(SYSTEM_ERROR_PAGE);
    //         })
    // };

    /* 
    * On Cancel
    */
    const cancelAppointmentDetails = () => {
        dispatch(resetAppointmentDetails());
        history.push(`/appointmenttype/${pGroupId}`);
    };

    return (
        <Container className="virtual-visit padding-bottom-large margin-top-large scroll">
            <BreadCrumb />
            <Row className="section-header inner-header">
                {' '}
                {/*Section Heading*/}
                <Col lg="12">
                    <div className="text-center">
                        <div onClick={history.goBack}>
                            <i className="fa-light fa-chevron-left" />
                        </div>
                        <span className="heading mx-auto">{headerTitle}</span>
                    </div>
                </Col>
            </Row>
            <br />
            <div className="box ">
                <Row className="section member">
                    <Col>
                        <p>Member requesting service</p>
                        <SelectMember multiSetectDisable={true} ></SelectMember>
                    </Col>
                </Row>
                <Row className="section">
                    <Col>
                        <p>{groupId2 === SOMETHINGELSE_GROUPID ? 'Describe your symptoms*' : groupId2 === VACCINATION_GROUPID || groupId2 === "14" ? 'Other Vaccination*' : groupId2 === LABSANDIMAGES_GROUPID || groupId2 === "15" ? 'Lab or Imaging Details*' : groupId === "17"? 'Covid Test Biofire *Additional Costs' : 'Describe your symptoms*'}</p>
                        <Form>
                            <FormGroup>
                                <RichTextEditor
                                    editorValue={notes}
                                    handleEditorChanged={setNotesData}
                                />
                            </FormGroup>
                        </Form>
                    </Col>
                </Row>
                {/* {!(groupId2 === VACCINATION_GROUPID) && <Row className="section attachment">
                    <Col>
                        <p>
                            Upload prescription and/or image (optional)
                        </p>
                        <span>
                            20MB max file size. <br /> Supported file formats:
                            .pdf, .doc, .xls, .jpg, .png
                        </span>
                        <div className="button-action btn-light">
                            <i className="fa-light fa-file" />
                            Upload File
                            {photoList || cameraImgList ? (
                                ''
                            ) : (
                                <input
                                    name="file"
                                    type="file"
                                    id="file"
                                    accept=".png,.jpg,.pdf,application/msword,
                        application/vnd.openxmlformats-officedocument.wordprocessingml.document,.doc,.docx,.txt"
                                    onChange={uploadImage}
                                />
                            )}
                        </div>
                        {!isCamClicked && progress && (
                            <Progress
                                animated
                                striped
                                value={progress}
                                label={`${progress}%`}
                            />
                        )}
                        {error && (
                            <p className="sub-txt add-attachment error">
                                {errMsg}
                                <i className="fa fa-warning" />
                            </p>
                        )}
                        {photoList?.map((it, index) => (
                            <p key={index} className="sub-txt add-attachment">
                                <span>{it.fileName}</span>
                                <i
                                    className={
                                        it.fileName
                                            ? 'fa-light fa-circle-xmark'
                                            : ''
                                    }
                                    onClick={() => deleteItem(it)}
                                />
                            </p>
                        ))}
                        <div className="button-action btn-border">
                            <i className="fa-light fa-camera" />
                            Take new picture
                            {photoList || cameraImgList ? (
                                ''
                            ) : (
                                <input
                                    accept="image/*"
                                    type="file"
                                    id="camera"
                                    capture="environment"
                                    onChange={uploadImage}
                                />
                            )}
                        </div>
                        {/* Todo:// need to change preview 
                        {isCamClicked && progress && (
                            <Progress
                                animated
                                striped
                                value={progress}
                                label={`${progress}%`}
                            />
                        )}
                        {cameraImgList && (
                            <p className="add-attachment">
                                <i
                                    className="fa-light fa-circle-xmark"
                                    onClick={() => deleteItem(cameraImgList[0])}
                                />
                                <img
                                    src={cameraImgList[0]?.imageUrl}
                                    alt="preview"
                                    width="160"
                                    padding="20px"
                                ></img>
                            </p>
                        )}
                    </Col>
                </Row>} */}
                <Row className="text-footer fixed-btm">
                    <Col className="button-group">
                        <div className="button-action btn-border" onClick={() => cancelAppointmentDetails()}>
                            Cancel
                        </div>
                        { (groupId2 !== "14" && groupId2 !== "15" && groupId2 !== "16") && (<div 
                            disabled={!canSubmit}
                            className={
                                canSubmit
                                    ? 'button-action'
                                    : 'button-action btn-disable button-action-not-allowed'
                            }
                            onClick={createAppointment}
                        >
                            {photoList || cameraImgList
                                ? 'SAVE AND SUBMIT'
                                : 'SUBMIT REQUEST'}
                        </div>)}
                        { ((groupId2 === "14" || groupId2 === "15" || groupId2 === "16") && notes.trim()) ? (
                        <Link
                            className="button-action "
                            to={ `/appointmentDetails/${pGroupId}/${groupId2}/${groupId}`}
                        >
                            Next
                        </Link>
                    ) : ( (groupId2 === "14" || groupId2 === "15" || groupId2 === "16") && !notes.trim() ?(
                        <span className="button-action btn-disable">Next</span>
                    ) : '' )}
                    </Col>
                </Row>
                <Modal
                    modalClassName='solis-modal2 apt-edit apt-loading'
                    isOpen={showAptLoader}
                    backdrop={true}
                    centered={true}
                >
                    <ModalBody>
                        <div className='apt-loading-img'>
                            <img
                                src='/images/appointment-webtechops.svg'
                                alt='appointment-webtechops'
                                className='svg-appointment-loading'
                            />
                        </div>
                        <div className='apt-loading-body'>
                            <h4>Creating your appointment request.</h4>
                            <p className='apt-loading-txt'>
                                <span>This might take a minute.</span>
                                <br />
                                <span>Thank you for your patience.</span>
                            </p>
                        </div>
                        <div id="loaders" class="divShow spinner">
                            <div class="global-spinner">
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                            </div>
                        </div>
                    </ModalBody>
                </Modal>
                {/* Sticky call footer */}
                <FooterCallUs />
            </div>
        </Container>
    );
}

export default NotSure;

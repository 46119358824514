/**
 * @author RamaTulasi <ramat@lancesoft.com>
 */
import React, { useState } from 'react';
import {
    Container,
    Row,
    Col,
    ListGroup,
    ListGroupItem,
    Button,
    Form,
    FormGroup,
    Label,
    Input,
} from 'reactstrap';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getMessageSubjectList, messageRequest, saveMessageContentRequest } from '../../services/RestApi';
import FooterCallUs from '../../components/FooterCallUs';
import BreadCrumb from '../../components/BreadCrumb';
import { useEffect, useRef } from 'react';
import SuccessModal from '../../components/SuccessModal';
import FailureModal from '../../components/FailureModal';
import Select from 'react-select';
import notify from '../../services/NotifyService';
import { SYSTEM_ERROR_PAGE } from '../../utilities/Constants';
import { checkVersionDiff } from '../../utilities/helper';
import { FaPaperclip, FaSquareXmark } from "react-icons/fa6";
import { RichTextEditor } from 'sollishealth.core.react';
import PreviewFileModal from '../../components/file-viewer/PreviewFileModal';

function ComposeMail() {
    const _memberDetails = useSelector((state) => state.memberDetails);
    const [previewFile, setPreviewFile] = useState(null);
    const [resMsg, setResMsg] = useState();
    const [subject, setSubject] = useState('');
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false);
    // const [responseBody, setResponseBody] = useState('');
    const history = useHistory();
    const [subjectList, setSubjectList] = useState([]);
    const dispatch = useDispatch();
    const inputFile = useRef(null);
    const [fileList, setFileList] = useState([]);
    const [totalFileSize, setTotalFileSize] = useState(0);
    const [showFileExceedMessage, setShowFileExceedMessage] = useState(false);

    useEffect(() => {
        getMessageSubjectList().then((res) => {
            checkVersionDiff(dispatch, res);
            res.data.map((it) =>
                subjectList.push({
                    label: it,
                    value: it,
                })
            );
            setSubjectList(subjectList);
        }).catch(err => history.push(SYSTEM_ERROR_PAGE));
        setResMsg();
    }, []);

    //It will send message to the data base
    const sendMessage = (e) => {
        setResMsg();
        e.preventDefault();
        
        let payload = {
            externalId: 'hi',
            header: subject.value,
            type: subject.value
        };

        setLoading(true);
        messageRequest(payload)
            .then((res) => {
                checkVersionDiff(dispatch, res);
                const data = res.data;
                payload = {
                    conversationId: data.conversationId,
                    externalId: data.externalId,
                    content: message + ' ',
                    staffMemberSentBy:  _memberDetails?.loginMember[0]?.memberFullname,
                    attachments: fileList
                }
                saveMessageContentRequest(payload).then((res) =>{
                    checkVersionDiff(dispatch, res);
                    history.push(`/messages/1`);
                    notify('Message sent', 'success');
                    setLoading(false);
                })
                .catch((e) => {
                    setResMsg('Failed');
                    setLoading(false);
                 }); 
            })
            .catch((e) => {
               setResMsg('Failed');
               setLoading(false);
            });
    };
    const onCancel = () => {
        let path = '/messages/1';
        history.push(path);
    };

    const onButtonClick = () => {
        if (inputFile.current !== null) {
          inputFile.current.click();
        }
      };

    const removeFile = (fileName) => {
      const files = fileList.filter((f) => f.fileName !== fileName);
      setFileList(files);
    };

    const handleFileUpload = (event) => {
      if (event.target.files) {
        const newFiles = Array.from(event.target.files);
        let newFileSize = 0;

        newFiles.forEach((file) => {
          newFileSize += file.size;
        });

        // Check if the total file size is smaller than 3MB (3145728 = 3 * 1024 * 1024 bytes)
        if (totalFileSize + newFileSize <= 3145728) {
          setTotalFileSize(totalFileSize + newFileSize);
          setShowFileExceedMessage(false);
          newFiles.forEach((file) => {
            const reader = new FileReader();
            reader.onloadend = () => {
              const contentBytes = reader.result;
              const cleanContentBytes = contentBytes.replace(/^data:.+;base64,/, '');
              const byteCharacters = atob(cleanContentBytes);
              const byteNumbers = new Array(byteCharacters.length);
              for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
              }
              const byteArray = new Uint8Array(byteNumbers);
              const blobUrl = URL.createObjectURL(new Blob([byteArray], { type: file.type }));
              setFileList((prevFiles) => [
                ...prevFiles,
                {
                    fileName: file.name,
                    fileSize: file.size,
                    contentBytes: cleanContentBytes,
                    contentType: file.type,
                    objectURL: blobUrl
                }
              ]);
            };
            reader.readAsDataURL(file);
          });
        } else {
          setShowFileExceedMessage(true);
        }
      }
    };

    return (
        <Container className="compose-email padding-bottom margin-top-large">
            {/* Breadcrumb */}
            <BreadCrumb subTitle="Messages" path="/messages/1" />
            <Row className="section-header inner-header">
                {' '}
                {/*Section Heading*/}
                <Col lg="12">
                    <div className="text-center">
                        <Link to="/messages/1">
                            <i className="fa-light fa-chevron-left" />
                        </Link>
                        <span className="heading mx-auto">Messages</span>
                    </div>
                </Col>
            </Row>

            <Row className="compose-msg">
                <Col lg="12">
                    <div className="button-group btn-left">
                        <div
                            className="button-action btn-border"
                            onClick={onCancel}
                        >
                            Cancel
                        </div>

                        {message && subject && !loading ? (
                            <div
                                onClick={sendMessage}
                                className="button-action btn-light"
                            >
                                <i class="fa-light fa-paper-plane-top" /> &nbsp;
                                send
                            </div>
                        ) : (
                            <div className="button-action btn-disable">
                                <i class="fa-light fa-paper-plane-top" /> &nbsp;
                                send
                            </div>
                        )}
                    </div>

                    <Form className="box compose">
                        <FormGroup>
                            <Label>
                                <span>From : </span>
                                <span>
                                    {
                                        _memberDetails?.loginMember[0]
                                            ?.memberUsername
                                    }
                                </span>
                            </Label>
                        </FormGroup>
                        <FormGroup>
                            <Label for="SubjectList" className="mb-2">
                                Subject:
                            </Label>
                            <Select
                                className="select-dropdown"
                                name="SubjectList"
                                options={subjectList}
                                isSearchable={false}
                                value={subject}
                                onChange={(it) => setSubject(it)}
                                theme={(theme) => ({
                                    ...theme,
                                    colors: {
                                        ...theme.colors,
                                        primary25: 'white',
                                        primary: '#64a4ae',
                                    },
                                })}
                            />
                        </FormGroup>
                        {subject?.value?.messageTo && (
                            <FormGroup>
                                <p className='mt-3'>To: {subject?.value?.messageTo}</p>
                            </FormGroup>
                        )}
                        <FormGroup className="mt-3">
                          <input title="File" type="file" id="file" ref={inputFile} style={{ display: 'none' }} multiple onChange={handleFileUpload} />
                          <FaPaperclip onClick={onButtonClick} style={{ cursor: 'pointer' }} />
                          {showFileExceedMessage && <Label color="red">Total file size cannot exceed 3 MB.</Label>}
                        </FormGroup>
                        {fileList?.length > 0 && (
                            <FormGroup className="mt-3">
                                <ListGroup horizontal>
                                    {fileList?.map((file, i) => (
                                        <ListGroupItem key={i} className="mb-2" style={{ display: 'flex', cursor: 'pointer' }} onClick={() => setPreviewFile(file)}>
                                            {file.fileName}{' '}
                                            <FaSquareXmark onClick={(event) => { event.stopPropagation(); removeFile(file.fileName); }} style={{ paddingLeft: '5px', cursor: 'pointer' }} />
                                        </ListGroupItem>
                                    ))}
                                </ListGroup>
                            </FormGroup>
                        )}
                        <FormGroup className="mt-3">
                            <RichTextEditor
                                editorValue={message}
                                handleEditorChanged={setMessage}
                            />
                        </FormGroup>
                    </Form>
                </Col>
            </Row>
            {resMsg === 'Success' ? (
                <SuccessModal
                    text={'Your message has been sent successfully.'}
                    path={'/messages/1'}
                />
            ) : resMsg === 'Failed' ? (
                <FailureModal />
            ) : (
                ''
            )}
            <FooterCallUs />
            {previewFile && <PreviewFileModal previewFile={previewFile} handleClose={() => setPreviewFile(null)} />}
        </Container>
    );
}

export default ComposeMail;

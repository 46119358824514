/**
 * @author RamaTulasi <rama.tulasi@sollishealth.com>
 */
import axios from 'axios';
import jwtDecode from 'jwt-decode';
import { ImpersonationService } from './impersonation';

import {
    READ_MESSAGE_DETAILS,
    GET_MEMBER_APPOINTMENT_REQUEST_DETAILS_LIST,
    APP_BASE_URL,
    MEMBER_LOGIN_DETAILS,
    APPOINTMENT_DETAILS,
    GET_CANCEL_REASON_DETAILS,
    APPOINTMENT_CANCEL_REASON,
    APPOINTMENT_TYPES,
    GET_SPECIALTY,
    ADD_APPOINTMENT,
    GET_APPOINTMENT_GROUPS,
    BILLING_DETAILS,
    GET_LAB_TEST_RESULTS,
    GET_LAB_TEST_RESULT_DETAILS,
    DOWNLOAD_BILLING_PDF,
    GET_LAB_REPORT_PDF,
    GET_UNREAD_LAB_RESULT_COUNT,
    DELETE_FILE,
    UPLOAD_FILE,
    GET_LOCATIONS,
    GET_APPOINTMENT_OPEN_SLOT,
    BOOK_APPOINTMENT,
    GET_APPOINTMENT_SCREENING_QA,
    GET_UPDATE_APPOINTMENT_ID,
    GET_SINGLE_MEMBER_APPOINTMENT_DETAILS,
    RESCHEDULE_APPOINTMENT,
    SCHEDULE_APPOINTMENT,
    MESSAGE_REQUEST,
    GET_UNREAD_MESSAGE_COUNT,
    GET_MESSAGE_SUBJECT_LIST,
    UPDATE_APPOINTMENT_REQUEST_DETAILS,
    GET_ALL_APPOINTMENTS,
    GET_APP_USER_MESSAGE_LIST,
    DELETE_APP_USER_MESSAGE,
    REPLY_APP_USER_MESSAGE,
    APPOINTMENT_CANCEL_Request,
    GET_STATE_LIST,
    GET_LOCATIONS_LIST,
    GET_NEAREST_LOCATION_ID,
    GET_MESSAGE_ATTACHMENT
} from '../utilities/Constants_AKSURL';

import { coordinates } from '../utilities/Coordinates';
/**
 *
 * @param {*} data: memberId
 * @returns Labtest results
 */

 axios.interceptors.request.use(
    config => {
        let token = sessionStorage.getItem('access_Token');
        if (token != null)
        {
            let decoded = jwtDecode(token);
            let email = decoded.emails[0];
            config.headers["sh-email"] = email;
        }

        config.headers['Authorization'] = `Bearer ${sessionStorage.getItem('access_Token')}`;
        
        ImpersonationService.mutateHeaders(config.headers);

        return config;
      },
      error => {
          return Promise.reject(error);
      }
  );
axios.interceptors.response.use(
    res => res,
    err => {
     
      if (err.response.status === 401 || err.response.status === 403) {
        
        //const [ removeCookie] = useCookies();
        sessionStorage.clear();
		localStorage.clear();
		//removeCookie('fx_info',{path:'/'});
		//removeCookie('fx_referrer',{path:'/'});
        window.location.href = "/login";
      }
      throw err;
    }
  );

export const GetLabTestResults = (data) => {
    const url = `${APP_BASE_URL}${GET_LAB_TEST_RESULTS}`;
    return axios.post(url, data, {
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
        },
    });
};
/**
 *
 * @param {*} data: memberId
 * @returns Labtest result details
 */
export const GetLabTestResultDetails = (data) => {
    const url = `${APP_BASE_URL}${GET_LAB_TEST_RESULT_DETAILS}`;
    return axios.post(url, data, {
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
        },
    });
};
/**
 *
 * @param {*} data : memberId
 * @returns Labreport Pdf
 */
export const GetLabReportPdf = (data) => {
    const url = `${APP_BASE_URL}${GET_LAB_REPORT_PDF}`;
    return axios.post(url, data, {
        method: 'post',
        responseType: 'blob',
        headers: {
            'Content-Type': 'application/json',
        },
    });
};
/**
 *
 * @param {*} data :Labresults data
 * @returns UnReadLabResultCount
 */
export const GetUnReadLabResultCount = (data) => {
    const url = `${APP_BASE_URL}${GET_UNREAD_LAB_RESULT_COUNT}`;
    return axios.post(url, data);
};
/**
 *
 * @param {*} data :email id
 * @returns Member details
 */

export const getMemberDetails = (data) => {
    
    
    const url = `${APP_BASE_URL}${MEMBER_LOGIN_DETAILS}`;
    return axios.post(url, data, {
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
        },
    });
};
/**
 *
 * @param {*} data :patientid
 * @returns UpcomingAppointmentDate
 */

export const getUpcomingAppointmentDate = (data) => {
    const url = `${APP_BASE_URL}${APPOINTMENT_DETAILS}`;
    return axios.post(url, data, {
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
        },
    });
};
/**
 *
 * @param {*} data :appointments
 * @returns UpcomingAppointments
 */
export const getUpcomingAppointments = (data) => {
    const url = `${APP_BASE_URL}${APPOINTMENT_DETAILS}`;
    return axios.post(url, data, {
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
        },
    });
};
/**
 *
 * @param {*} data :appointment id
 * @returns canceling the appointment
 */
export const cancelAppointment = (data, type) => {
    let url = '';
    if (type === 'upcoming') {
        url = `${APP_BASE_URL}${APPOINTMENT_CANCEL_REASON}`;
    } else {
        url = `${APP_BASE_URL}${APPOINTMENT_CANCEL_Request}`;
    }

    return axios.post(url, data, {
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
        },
    });
};

/**
 *
 * @returns AppointmentCancelReasons
 */
export const getAppointmentCancelReasons = () => {
    const url = `${APP_BASE_URL}${GET_CANCEL_REASON_DETAILS}`;
    return axios.get(url);
};
/**
 *
 * @param {*} params : groupId
 * @returns AppointmentTypes
 */
export const GetAppointmentTypes = (params) => {
    const url = `${APP_BASE_URL}${APPOINTMENT_TYPES}`;

    return axios.get(url, { params });
};
/**
 *
 * @returns specialities
 */
export const GetSpecialty = () => {
    const url = `${APP_BASE_URL}${GET_SPECIALTY}`;
    return axios.get(url);
};
/**
 *
 * @param {*} data :appointmentdetais
 * @returns  AddNewAppointment
 */

export const addNewAppointment = (data) => {
    const url = `${APP_BASE_URL}${ADD_APPOINTMENT}`;
    return axios.post(url, data, {
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
        },
    });
};
/**
 *
 * @param {*} data GroupId
 * @returns AppointmentGroupsQA
 */
export const GetAppointmentGroupsQA = (data) => {
    const url = `${APP_BASE_URL}${GET_APPOINTMENT_GROUPS}`;
    return axios.post(url, data, {
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
        },
    });
};

/**
 *
 * @param {*} data :Member Id
 * @returns BillingDetails
 */
export const GetBillingDetails = (data) => {
    const url = `${APP_BASE_URL}${BILLING_DETAILS}`;
    return axios.post(url, data, {
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
        },
    });
};
/**
 *
 * @param {*} params :MemberId
 * @returns DownloadBillingPdf
 */
export const GetDownloadBillingPdf = (params) => {
    const url = `${APP_BASE_URL}${DOWNLOAD_BILLING_PDF}`;
    return axios.post(url, params, {
        method: 'post',
        responseType: 'blob',
        headers: {
            'Content-Type': 'application/json',
        },
        params: params,
    });
};

/**
 *
 * @param {*} data :bills
 * @returns UnReadMessageCount
 */
export const GetUnReadMessageCount = (data) => {
    const url = `${APP_BASE_URL}${GET_UNREAD_MESSAGE_COUNT}`;
    return axios.get(url, data);
};

/**
 *
 * @param {*} file
 * @param {*} onUploadProgress
 * @returns file upload
 */

export const uploadFile = (file, onUploadProgress) => {
    const formData = new FormData();
    formData.append('ImageType', 'JPG');
    formData.append('EntityTypeId', 'P10000258');
    formData.append('FileName', file.name);
    formData.append('ImageFile', file);
    const url = `${APP_BASE_URL}${UPLOAD_FILE}`;
    return axios.post(url, formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
        onUploadProgress,
    });
};
/**
 *
 * @param {*} imageUrl
 * @returns file deletion
 */
export const deleteFile = (imageUrl) => {
    const url = `${APP_BASE_URL}${DELETE_FILE}`;
    return axios(`${url}?Url=${imageUrl}`);
};
/**
 *
 * @returns locations
 */
export const getLocations = () => {
    const url = `${APP_BASE_URL}${GET_LOCATIONS}`;

    return axios.get(url);
};

export const getLocationsList = () => {
    const url = `${APP_BASE_URL}${GET_LOCATIONS_LIST}`;

    return axios.post(url, {
        showAvailableAthenaDepartmentOnly: true,
    });
};

/**
 *
 * @returns nearest location id. state
 */
 export const getNearestLocationId = (payload) => {
    const url = `${APP_BASE_URL}${GET_NEAREST_LOCATION_ID}`;

    return axios.post(url, payload);
};
/**
 *
 * @returns states list
 */
export const getStateList = () => {
    const url = `${APP_BASE_URL}${GET_STATE_LIST}`;
    return axios.get(url);
};

/**
 *
 * @returns message subject list
 */
export const getMessageSubjectList = () => {
    const url = `${APP_BASE_URL}${GET_MESSAGE_SUBJECT_LIST}`;

    return axios.get(url);
};

/**
 *
 * @returns screening question & Answers
 */
export const GetAppointmentScreeningQA = () => {
    const url = `${APP_BASE_URL}${GET_APPOINTMENT_SCREENING_QA}`;

    return axios.get(url);
};
export const getAppointmentOpenSlot = (data) => {
    const url = `${APP_BASE_URL}${GET_APPOINTMENT_OPEN_SLOT}`;
    return axios.post(url, data, {
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
        },
    });
};
/**
 *
 * @param {*} data
 * @returns appointment id
 */
export const bookNewAppointment = (data) => {
    const url = `${APP_BASE_URL}${BOOK_APPOINTMENT}`;
    return axios.post(url, data, {
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
        },
    });
};
/**
 *
 * @param {*} passing appointmentRequestID,appointmentID
 * @returns Update appointmentID
 */
export const updateAppointmentID = (data) => {
    const url = `${APP_BASE_URL}${GET_UPDATE_APPOINTMENT_ID}`;
    return axios.post(url, data, {
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
        },
    });
};
/**
 *
 * @param {*} passing appointmentRequestdetails
 * @returns appoitnemtRequestID
 */
export const GetMemberAppointmentRequestDetailsList = (data) => {
    const url = `${APP_BASE_URL}${GET_MEMBER_APPOINTMENT_REQUEST_DETAILS_LIST}`;
    return axios.post(url, data, {
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
        },
    });
};
/**
 *
 * @param {*}  passing appointmentRequestId,appointmentId
 * @returns SingleMemberAppointmentDetails
 */
/**
 *
 * @param {*} data :appointment id
 * @returns SingleMemberAppointmentDetails
 */
export const GetSingleMemberAppointmentDetails = (data) => {
    const url = `${APP_BASE_URL}${GET_SINGLE_MEMBER_APPOINTMENT_DETAILS}`;
    return axios.post(url, data);
};
/**
 *
 * @param {*} data :appointment id
 * @returns new appointment id
 */
export const rescheduleAppointment = (data) => {
    const url = `${APP_BASE_URL}${RESCHEDULE_APPOINTMENT}`;
    return axios.post(url, data);
};
export const UpdateAppointmentRequestDetails = (data) => {
    const url = `${APP_BASE_URL}${UPDATE_APPOINTMENT_REQUEST_DETAILS}`;
    return axios.post(url, data);
};

/**
 *
 * @param {*} data : appointment details
 * @returns create appointment
 */
export const scheduleAppointment = (data) => {
    const url = `${APP_BASE_URL}${SCHEDULE_APPOINTMENT}`;
    return axios.post(url, data, {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
    });
};
export const GetAllAppointments = (data) => {
    const url = `${APP_BASE_URL}${GET_ALL_APPOINTMENTS}`;
    return axios.post(url, data);
};
/**
 *
 * @param {*} data :message details
 * @returns: message
 */
export const messageRequest = (data) => {
    const url = `${APP_BASE_URL}${MESSAGE_REQUEST}`;
    return axios.post(url, data);
};
/**
 *
 * @param {*} data :message details
 * @returns: message
 */
 export const saveMessageContentRequest = (data) => {
    const url = `${APP_BASE_URL}${MESSAGE_REQUEST}/${data.conversationId}`;
    return axios.post(url, data);
};
/**
 *
 * @param {*} attachmentPath :message attachment path
 * @returns: message attachment
 */
export const downloadMessageAttachment = (attachmentPath) => {
    const url = `${APP_BASE_URL}${GET_MESSAGE_ATTACHMENT}?filePath=${encodeURI(attachmentPath)}`;
    return axios.post(url);
};

export const downloadLabFile = (labResultId, fileName) => {
    const url = `${APP_BASE_URL}/lab/v1/GetLabResultFile/${labResultId}/${fileName}`;
    return axios.get(url);
}
/**
 *
 * @param {*} data :selected members, message type
 * @returns: message list
 */
export const getAppUserMessageList = (data) => {
    const url = `${APP_BASE_URL}${GET_APP_USER_MESSAGE_LIST}`;
    return axios.get(url, data);
};

export const getAllConversationList = (data) => {
    const url = `${APP_BASE_URL}${MESSAGE_REQUEST}`;
    return axios.get(url, data);
};

export const getConversationById = (conversationId) => {
    const url = `${APP_BASE_URL}${MESSAGE_REQUEST}/${conversationId}`;
    return axios.get(url);
};

/**
 *
 * @param {*} data :Message header id
 *
 */
export const deleteMessage = (conversationId) => {
    const url = `${APP_BASE_URL}${DELETE_APP_USER_MESSAGE}${conversationId}`;
    return axios.delete(url);
};
/**
 *
 * @param {*} data :Message header id
 *
 */
export const readMessageDetails = (msgid) => {
    const url = `${APP_BASE_URL}${READ_MESSAGE_DETAILS}${msgid}/messages`;
    return axios.get(url);
};
/**
 *
 * @param {*} data :Message header id
 *
 */
export const replyAppUserMessage = (data) => {
    const url = `${APP_BASE_URL}${REPLY_APP_USER_MESSAGE}`;
    return axios.post(url, data);
};

//get Patient details from memberdetails
export const getPatientDetails = (memberDetails) => {
    let loginMember = memberDetails?.loginMember || [];
    let patientids = [];
    let patientDetails = {};
    let memberIds = [];
    let patientDetailsMember = {};

    if (loginMember && loginMember.length > 0) {
        patientids.push({
            patientid: loginMember[0].patientID,
        });
        memberIds.push({
            memberId: loginMember[0].memberID,
        });
        patientDetails[loginMember[0].patientID] = { ...loginMember[0] };
        patientDetailsMember[loginMember[0].memberID] = { ...loginMember[0] };
    }
    let addedDependents = memberDetails?.dependents || [];

    if (memberDetails && memberDetails.loginMember) {
    } else {
        return {
            patientids,
            addedDependents,
            patientDetails,
            memberIds,
            patientDetailsMember,
        };
    }

    if (addedDependents && addedDependents.length) {
        addedDependents.forEach((d) => {
            if (d.selected) {
                patientids.push({
                    patientid: d.patientID,
                });
                memberIds.push({
                    memberId: d.memberID,
                });
                patientDetails[d.patientID] = { ...d };
                patientDetailsMember[d.memberID] = { ...d };
            }
        });
    }
    return {
        patientids,
        addedDependents,
        patientDetails,
        memberIds,
        patientDetailsMember,
    };
};

export const getStateCoOrdinates = (state) => {
    return coordinates[state];
};

import moment from 'moment';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import {
    Col,
    Container,
    Input,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row,
} from 'reactstrap';
import BreadCrumb from '../../components/BreadCrumb';
import FooterCallUs from '../../components/FooterCallUs';
import {
    resetAppointmentDetails,
    resetNotes,
    storeAppointmentDetails,
} from '../../features/appointmentDetailsSlice';
import { storeMemberDetails } from '../../features/memberDetailsSlice';
import { scheduleAppointment, messageRequest, saveMessageContentRequest } from '../../services/RestApi';

import { resetAppointments } from '../../features/appointmentSlice';
import SelectMember from './SelectMember';
import { SYSTEM_ERROR_PAGE } from '../../utilities/Constants';
import { checkVersionDiff } from '../../utilities/helper';
import { useImpersonation } from '../../components/impersonation-provider';

function EditAppointment() {
    const impersonation = useImpersonation();
    const history = useHistory();
    const dispatch = useDispatch();
    let { pGroupId, groupId2, groupId } = useParams();
    const [isBtnEnabled, setIsBtnEnabled] = useState(false);
    const _appointmentDetails = useSelector(
        (state) => state.appointmentDetails.appointmentDetails
    );
    const _notes = useSelector(
        (state) => state.appointmentDetails.notes
    );
    const _appointmentTypesFL = useSelector(
        (state) => state.appointmentTypesFL.appointmentTypesFL
    );
    const [selectedCovidCareOption, setSelectedCovidCareOption] = useState({});
    const [selectedDate, setSelectedDate] = useState();
    const [open, setOpen] = useState(false);
    const [editMemberMode, setEditMemberMode] = useState(false);
    const [aptTimeUnavailable, setAptTimeUnavailable] = useState(false)

    const _memberStore = useSelector((state) => state.memberDetails);
    const [loading, setLoading] = useState(false);
    const [showAptLoader, setShowAptLoader] = useState(false);

    const _covidCareOptions = useSelector((state) => state.covidCareOptions);
    const [specialInstructions, setSpecialInstructions] = useState(
        _appointmentDetails?.location?.specialInstructions || ''
    );
    const [phoneNumber, setPhoneNumber] = useState(
        _appointmentDetails?.phoneNumber
    );
    
    const headerTitle = _appointmentTypesFL[Number(pGroupId)]?.filter(
        (it) => it.groupId == groupId2
    )[0]?.groupName;

    const getCallBackNumber = () => {
        let phone =
            _memberStore?.loginMember[0]?.primaryPhoneNumber ||
            _memberStore?.loginMember[0]?.secondaryPhoneNumber ||
            _memberStore?.loginMember[0]?.mobileNumber ||
            '';

        return phone;
    };
    const getCurrentMember = () => {
        if (
            Array.isArray(_appointmentDetails.currentMember) &&
            _appointmentDetails.currentMember.length > 1
        ) {
            return _memberStore?.loginMember[0];
        }
        return _appointmentDetails.currentMember;
    };
    const handleClose = () => {
        setOpen(false);
    };

    const getAthenaNotes = () => {
        if(_notes) {
            return _notes;
        }
        let temp = [_appointmentDetails.groupName];
        let vt = getSelectedVactinationTypes();
        vt.forEach(v => {
            //temp.push(v.groupName);
            temp.push(v.answerDescription);
            if (v.children && v.children.length) {
                v.children.forEach(t => {
                    temp.push(t.answerDescription);
                })
            }
        });
        return temp.join("-")

    }

    const getSelectedVactinationTypes = () => {
        let temp = []
        if (!_appointmentDetails.vactinationTypes) {
            return temp;
        }
        let _vt = JSON.parse(JSON.stringify(_appointmentDetails.vactinationTypes || {}));
        for (let g in _vt) {
            let found = _vt[g][0].find(vt => vt.isActive);
            if (found) {
                if (found.children) {
                    let _t = [];
                    for (let c in found.children) {
                        let _found = found.children[c].find(_g => _g.isActive);
                        if (_found) {
                            _t.push(_found);
                        }
                    }
                    found.children = _t;
                }
                temp.push(found);
            }
        }
        return temp;
    }

    const getPayload = () => {
        let currentMember = getCurrentMember();
        let appointmentRequestDetails = [];
        let appointmentFor = currentMember;
        let memberIds = [];
        let tempDate = new Date();
        let notes = '';
        switch(Number(groupId2)) {
            // case 9: // this is labs ID. Not needed here.
            // case 10: // Something else ID. Not needed here.
            // case 15: // this is labs ID. Not needed here.
            // case 16: // Something else ID. Not needed here.
            case 5:
            case 8:
            case 11:
            case 14:
                if (_appointmentDetails?.screeningQuestion?.answersDescription == 'Yes') notes = 'Covid Screening - possible exposure or diagnosis,';
                else notes = 'Covid Screening - passed,';
                break;
            case 6:
            case 12:
                if (_appointmentDetails?.screeningQuestion?.answersDescription == 'Yes') notes = 'Covid Screening - possible exposure or diagnosis, Med Eval,';
                else notes = 'Covid Screening - passed, Med Eval,';
                break;
            default:
                break;
        }
        if (_appointmentDetails?.time?.customapptime) {
            tempDate.setHours(tempDate.getHours() + 24);
        } else {
            tempDate.setHours(tempDate.getHours() + 2);
        }
        if (Array.isArray(_appointmentDetails.currentMember)) {
            _appointmentDetails.currentMember.forEach((m) => {
                let temp = {
                    memberID: m.memberID,
                    patientid: m.patientID,
                    memberName: m.memberFullname
                };
                memberIds.push(temp);
            });
        } else {
            memberIds.push({
                memberID: _appointmentDetails.currentMember.memberID,
                patientid: _appointmentDetails.currentMember.patientID,
            });
        }

        appointmentRequestDetails = [
            {
                appointmentFor: appointmentFor.patientID,
                selectedDate:
                    _memberStore?.loginMember[0].type != 'House Call'
                        ? _appointmentDetails?.time?.customapptime
                            ? ''
                            : moment(selectedDate).format('llll')
                        : selectedDate
                            ? moment(selectedDate).format('llll') +
                            ',' +
                            _appointmentDetails.time.starttime +
                            '-' +
                            _appointmentDetails.time.endtime
                            : _appointmentDetails.time.time || '',
                appointmentForMemberIds: memberIds,
                memberName: appointmentFor.memberUsername,
                memberID: appointmentFor.memberID,
                mobileNumber: getCallBackNumber(),
                phoneNumber: _appointmentDetails.phoneNumber,
                // remarks: comments || _appointmentDetails.comments,
                locationId:
                    pGroupId === '2'
                        ? 0
                        : Number(_appointmentDetails.location.departmentid),
                locationDetails:
                    pGroupId === '2'
                        ? _appointmentDetails?.location?.memberaddress
                        : _appointmentDetails?.location?.address + ',' + _appointmentDetails?.location?.city + ',' + _appointmentDetails?.location?.state + ',' + _appointmentDetails?.location?.zip,
                locationName: pGroupId === '2'
                    ? _appointmentDetails?.location?.memberaddress
                    : _appointmentDetails?.location?.name,
                specialInstructions: specialInstructions || null,
                appointmentid:
                    pGroupId === '2'
                        ? 0
                        : _appointmentDetails?.time?.customapptime
                            ? ''
                            : _appointmentDetails.time.appointmentid,
                multimemberappointment:
                    _appointmentDetails.multiMemberAppointment == 'No'
                        ? false
                        : true,
                loginMemberId: _memberStore.loginMember[0].memberID,
                appointmentgroupLastLevelId: Number(groupId),
                appointmentgroupFirstLevelId: Number(groupId2),
                appointmentgroupLastLevelGroupName:
                    _appointmentDetails.groupName || '',
                appointmentMaingroupFirstLevelId: Number(pGroupId),
                loginUserName: _memberStore.loginMember[0].memberUsername,
                patientid: pGroupId === '2' ? 0 : currentMember.patientID,
                screeningQuestion: _appointmentDetails.screeningQuestion || {},
                vactinationTypes: getSelectedVactinationTypes(),
                athenaNotes : `${notes} ${getAthenaNotes()}`
            },
        ];

        if (_appointmentDetails?.time?.customapptime) {
            appointmentRequestDetails[0].customAppTimeComments =
                _appointmentDetails?.time?.customAppTimeComments;
        }

        let appointmentTypeIDForPayload = '';
        if (Number(groupId2) === 5) {
            // for covid appointments - to fetch athenTypeID from the store
            appointmentTypeIDForPayload = _covidCareOptions.covidCareOptions[groupId2].find(item => item.groupId == Number(groupId)).athenaTypeMappingID;
        } else if (Number(groupId2) === _appointmentDetails?.parentGroupId) {
            appointmentTypeIDForPayload = _appointmentDetails.athenaTypeMappingID;
        } else appointmentTypeIDForPayload = _appointmentDetails.time.appointmenttypeid;

        let payload = {
            multimemberappointment:
                _appointmentDetails.multiMemberAppointment == 'No'
                    ? false
                    : true,
            loginMemberId: _memberStore.loginMember[0].memberID,
            appointmentgroupLastLevelId: Number(groupId),
            appointmentgroupFirstLevelId: Number(pGroupId),
            loginUserName: _memberStore.loginMember[0].memberUsername,
            appointmentForMemberIds: memberIds,
            //athenaNotes: getAthenaNotes(),

            appointmentDate: selectedDate || tempDate,
            scheduleAppointment: {
                appointmentid:
                    pGroupId === '2'
                        ? 0
                        : _appointmentDetails?.time?.customapptime
                            ? 0
                            : _appointmentDetails.time.appointmentid,
                appointmenttypeid:
                    pGroupId === '2'
                        ? 0
                        : _appointmentDetails?.time?.customapptime
                            ? 0
                            : appointmentTypeIDForPayload,
                locationId:
                    pGroupId === '2'
                        ? 0
                        : Number(_appointmentDetails.location.departmentid), //need to change for house call
                patientid: pGroupId === '2' ? 0 : currentMember.patientID, //need to change for house call
                otherSlots: _appointmentDetails?.time?.customapptime
                    ? ''
                    : _appointmentDetails.time.otherSlots
                // appointmentDate: '06/10/2022',
                // appointmentTime: '12:00',
            },
            appointmentRequest: {
                appointmentRequestDetails: JSON.stringify(
                    appointmentRequestDetails[0]
                ),
                memberInfo: '',
                detailsForTask: '',
            },
            // uploadImages: {
            //     imageDetails: uploadImages,
            // },
        };
        if (_appointmentDetails?.time?.customapptime) {
            payload = {
                ...payload,
                customapptime: true,
                customAppTimeComments:
                    _appointmentDetails?.time?.customAppTimeComments,
            };
        }

        return payload;
    };

    const getProperties = (obj) => { 
        let result = "";
        for (const entry of Object.entries(obj)) 
        {
            let key = entry[0];
            let value = entry[1];
            if(typeof(value) === 'object')
            {
                value = getProperties(value);
                value = '\r\n' + value;
            }
            result = result + key + ': ' + value + ',';
        }
        result = result.replaceAll(',,', ',');
        return result.replaceAll(',', '\r\n');
    }

    const getMessageDetails = () => {
        let currentMember = getCurrentMember();
        let appointmentRequestDetails = [];
        let appointmentFor = currentMember;
        let memberIds = [];
        let tempDate = new Date();
        let notes = '';
        switch(Number(groupId2)) {
            // case 9: // this is labs ID. Not needed here.
            // case 10: // Something else ID. Not needed here.
            // case 15: // this is labs ID. Not needed here.
            // case 16: // Something else ID. Not needed here.
            case 5:
            case 8:
            case 11:
            case 14:
                if (_appointmentDetails?.screeningQuestion?.answersDescription == 'Yes') notes = 'Covid Screening - possible exposure or diagnosis,';
                else notes = 'Covid Screening - passed,';
                break;
            case 6:
            case 12:
                if (_appointmentDetails?.screeningQuestion?.answersDescription == 'Yes') notes = 'Covid Screening - possible exposure or diagnosis, Med Eval,';
                else notes = 'Covid Screening - passed, Med Eval,';
                break;
            default:
                break;
        }
        if (_appointmentDetails?.time?.customapptime) {
            tempDate.setHours(tempDate.getHours() + 24);
        } else {
            tempDate.setHours(tempDate.getHours() + 2);
        }
        if (Array.isArray(_appointmentDetails.currentMember)) {
            _appointmentDetails.currentMember.forEach((m) => {
                let temp = {
                    memberID: m.memberID,
                    patientid: m.patientID,
                    memberName: m.memberFullname
                };
                memberIds.push(temp);
            });
        } else {
            memberIds.push({
                memberID: _appointmentDetails.currentMember.memberID,
                patientid: _appointmentDetails.currentMember.patientID,
            });
        }

        appointmentRequestDetails = [
            {
                appointmentFor: appointmentFor.patientID,
                selectedDate:
                    _memberStore?.loginMember[0].type != 'House Call'
                        ? _appointmentDetails?.time?.customapptime
                            ? ''
                            : moment(selectedDate).format('llll')
                        : selectedDate
                            ? moment(selectedDate).format('llll') +
                            ',' +
                            _appointmentDetails.time.starttime +
                            '-' +
                            _appointmentDetails.time.endtime
                            : _appointmentDetails.time.time || '',
                appointmentForMemberIds: memberIds,
                memberName: appointmentFor.memberUsername,
                memberID: appointmentFor.memberID,
                mobileNumber: getCallBackNumber(),
                phoneNumber: _appointmentDetails.phoneNumber,
                // remarks: comments || _appointmentDetails.comments,
                locationId:
                    pGroupId === '2'
                        ? 0
                        : Number(_appointmentDetails.location.departmentid),
                locationDetails:
                    pGroupId === '2'
                        ? _appointmentDetails?.location?.memberaddress
                        : _appointmentDetails?.location?.address + ',' + _appointmentDetails?.location?.city + ',' + _appointmentDetails?.location?.state + ',' + _appointmentDetails?.location?.zip,
                locationName: pGroupId === '2'
                    ? _appointmentDetails?.location?.memberaddress
                    : _appointmentDetails?.location?.name,
                specialInstructions: specialInstructions || null,
                appointmentid:
                    pGroupId === '2'
                        ? 0
                        : _appointmentDetails?.time?.customapptime
                            ? ''
                            : _appointmentDetails.time.appointmentid,
                multimemberappointment:
                    _appointmentDetails.multiMemberAppointment == 'No'
                        ? false
                        : true,
                loginMemberId: _memberStore.loginMember[0].memberID,
                appointmentgroupLastLevelId: Number(groupId),
                appointmentgroupFirstLevelId: Number(groupId2),
                appointmentgroupLastLevelGroupName:
                    _appointmentDetails.groupName || '',
                appointmentMaingroupFirstLevelId: Number(pGroupId),
                loginUserName: _memberStore.loginMember[0].memberUsername,
                patientid: pGroupId === '2' ? 0 : currentMember.patientID,
                screeningQuestion: _appointmentDetails.screeningQuestion || {},
                vactinationTypes: getSelectedVactinationTypes(),
                athenaNotes : `${notes} ${getAthenaNotes()}`
            },
        ];

        if (_appointmentDetails?.time?.customapptime) {
            appointmentRequestDetails[0].customAppTimeComments =
                _appointmentDetails?.time?.customAppTimeComments;
        }

        let appointmentTypeIDForPayload = '';
        if (Number(groupId2) === 5) {
            // for covid appointments - to fetch athenTypeID from the store
            appointmentTypeIDForPayload = _covidCareOptions.covidCareOptions[groupId2].find(item => item.groupId == Number(groupId)).athenaTypeMappingID;
        } else if (Number(groupId2) === _appointmentDetails?.parentGroupId) {
            appointmentTypeIDForPayload = _appointmentDetails.athenaTypeMappingID;
        } else appointmentTypeIDForPayload = _appointmentDetails.time.appointmenttypeid;

        let payload = {
            multimemberappointment:
                _appointmentDetails.multiMemberAppointment == 'No'
                    ? false
                    : true,
            loginMemberId: _memberStore.loginMember[0].memberID,
            appointmentgroupLastLevelId: Number(groupId),
            appointmentgroupFirstLevelId: Number(pGroupId),
            loginUserName: _memberStore.loginMember[0].memberUsername,
            appointmentForMemberIds: memberIds,
            //athenaNotes: getAthenaNotes(),

            appointmentDate: selectedDate || tempDate,
            scheduleAppointment: {
                appointmentid:
                    pGroupId === '2'
                        ? 0
                        : _appointmentDetails?.time?.customapptime
                            ? 0
                            : _appointmentDetails.time.appointmentid,
                appointmenttypeid:
                    pGroupId === '2'
                        ? 0
                        : _appointmentDetails?.time?.customapptime
                            ? 0
                            : appointmentTypeIDForPayload,
                locationId:
                    pGroupId === '2'
                        ? 0
                        : Number(_appointmentDetails.location.departmentid), //need to change for house call
                patientid: pGroupId === '2' ? 0 : currentMember.patientID, //need to change for house call
                otherSlots: _appointmentDetails?.time?.customapptime
                    ? ''
                    : _appointmentDetails.time.otherSlots
                // appointmentDate: '06/10/2022',
                // appointmentTime: '12:00',
            },
            appointmentRequest: {
                appointmentRequestDetails: JSON.stringify(
                    appointmentRequestDetails[0]
                ),
                memberInfo: '',
                detailsForTask: '',
            },
            // uploadImages: {
            //     imageDetails: uploadImages,
            // },
        };
        if (_appointmentDetails?.time?.customapptime) {
            payload = {
                ...payload,
                customapptime: true,
                customAppTimeComments:
                    _appointmentDetails?.time?.customAppTimeComments,
            };
        }

        //let message = getProperties(payload);
        var today  = new Date(payload.appointmentDate);
        var options = { weekday: 'long', year: 'numeric', month: 'short', day: 'numeric' };
        var dateString = today.toLocaleDateString("en-US", options);
        var apptFor =  _memberStore.loginMember[0].memberFullname;
        var thisType = selectedCovidCareOption.groupName || headerTitle;

        let message = `Type: ${thisType}\r\nRequest Date: ${dateString}\r\nStatus: New\r\nFor Member: ${apptFor}\r\nAppointment For: ${apptFor}\r\n Member ID: ${payload.loginMemberId}`;
        if (_memberStore?.loginMember[0].type !== 'House Call')
        {
            message = message + `\r\nMobile Number: ${appointmentRequestDetails[0].mobileNumber}\r\nPhone Number: ${appointmentRequestDetails[0].phoneNumber}\r\nNotes: ${appointmentRequestDetails[0].athenaNotes}`;
        } else {
            // Covid Screening - passed, Covid Treatment-Unsure
            let covidScreen = appointmentRequestDetails[0].screeningQuestion?.answersDescription === 'No' ? 'Covid Screening - passed' : 'Covid Screening - possible exposure or diagnosis';
            appointmentRequestDetails[0].athenaNotes = appointmentRequestDetails[0].athenaNotes === ' Other' ? `${covidScreen}, Other` : appointmentRequestDetails[0].athenaNotes;
            message = message + `\r\nPreferred Number: ${appointmentRequestDetails[0].phoneNumber}\r\nNotes: ${appointmentRequestDetails[0].athenaNotes}`;
        }
        
        if (_memberStore?.loginMember[0].type === 'House Call')
        {
            message = message + `\r\nSpecial Instructions:${appointmentRequestDetails[0].specialInstructions}`;
            message = message + `\r\nAddress:${appointmentRequestDetails[0].locationDetails}`;
        }

         return message;
     };

     //header: `Appointment Request`,
     //type: `Appointment Request`

    const createAppointment = () => {
        setShowAptLoader(true)
        setIsBtnEnabled(false);
        setLoading(true);
        let message = getMessageDetails() + ' ';
        let payload = {
           externalId: 'House Call',
           header: _memberStore?.loginMember[0].type === 'House Call' ? `House Call Request` : 'Appointment Request',
           type: _memberStore?.loginMember[0].type === 'House Call' ? `House Call Request` : 'Appointment Request',
           stateCode: _appointmentDetails?.location?.state
       };
        messageRequest(payload)
           .then((res) => {
            checkVersionDiff(dispatch, res);
               const data = res.data;
               payload = {
                   conversationId: data.conversationId,
                   externalId: data.externalId,
                   content: message,
                   staffMemberSentBy:  _memberStore?.loginMember[0]?.memberFullname
               }
               saveMessageContentRequest(payload).then((res) =>{
                checkVersionDiff(dispatch, res);
                setShowAptLoader(false);
                // alert('Appointment Booked Successfully.');
                updateAppointmentDetails(res.data.appointmentRequest);
                dispatch(resetAppointments());
                let path = `/confirmAppointment/${pGroupId}/${groupId2}/${groupId}`;
                history.push(path);
               })
               .catch((e) => {
                   //notify('Message not sent','error');
                   if (e?.response?.data?.isAppointmentSlotAvailable) {
                    setAptTimeUnavailable(true)
                }
                setShowAptLoader(false);
                history.push(SYSTEM_ERROR_PAGE)
                }); 
           })
           .catch((e) => {
              //notify('Message not sent','error');
              if (e?.response?.data?.isAppointmentSlotAvailable) {
                setAptTimeUnavailable(true)
            }
            setShowAptLoader(false);
            history.push(SYSTEM_ERROR_PAGE)
           })
           .finally(() => {
               setLoading(false);
           });
    };

    const onUpdate = () => {
        setShowAptLoader(true)
        setIsBtnEnabled(false);
        // let temp = JSON.parse(JSON.stringify(_appointmentDetails));
        // temp.location.specialInstructions = specialInstructions;
        // dispatch(storeAppointmentDetails(temp));
        let payload = getPayload();
        setLoading(true);
        scheduleAppointment(payload)
            .then((res) => {
                checkVersionDiff(dispatch, res);
                setShowAptLoader(false);
                updateAppointmentDetails(res.data.appointmentRequest);
                dispatch(resetAppointments());
                dispatch(resetNotes());
                let path = `/confirmAppointment/${pGroupId}/${groupId2}/${groupId}`;
                history.push(path);
            })
            .catch((e) => {
                if (e?.response?.data?.isAppointmentSlotAvailable) {
                    setAptTimeUnavailable(true)
                }
                setShowAptLoader(false);
                history.push(SYSTEM_ERROR_PAGE)
                //alert(e);
            })
            // .finally(() => {
            //     setIsBtnEnabled(true);

            //     setLoading(false);
            // });
    };

    const getAppointmentsFor = () => {
        if (_appointmentDetails.vactinationTypes) {
            for (let type in _appointmentDetails.vactinationTypes) {
                let found = _appointmentDetails.vactinationTypes[type][0].find(
                    (vt) => vt.isActive
                );

                if (found) {
                    if (found.children) {
                        for (let type2 in found.children) {
                            let found2 = found.children[type2].find(
                                (c2) => c2.isActive
                            );
                            if (found2) {
                                setSelectedCovidCareOption(found2);
                                break;
                            }
                        }
                    }
                    setSelectedCovidCareOption(found);
                    break;
                }
            }
        }
    };

    useEffect(() => {
        getAppointmentsFor();
        if (
            _appointmentDetails.time &&
            !_appointmentDetails.time.customapptime
        ) {
            if (
                !_appointmentDetails.time.time ||
                _appointmentDetails.time.time !== 'ASAP'
            ) {
                let d = new Date(_appointmentDetails.time.date);
                if (!_appointmentDetails.time.time) {
                    let arr = _appointmentDetails.time.starttime.split(':');
                    d.setHours(arr[0]);
                    d.setMinutes(arr[1]);
                }
                if (pGroupId === '2') {
                    d = new Date(
                        _appointmentDetails.time.date +
                        ' ' +
                        _appointmentDetails.time.starttime.slice(0, -2) +
                        ':00 ' +
                        _appointmentDetails.time.starttime.slice(-2)
                    );
                }

                setSelectedDate(d);
            }
        }
    }, []);

    /*
     * To upload file to database
     */

    const cancelAppointmentDetails = () => {
        setOpen(true);

        // history.push('/appointmenttype');
    };
    const handleCancel = () => {
        dispatch(resetAppointmentDetails());
        let md = JSON.parse(JSON.stringify(_memberStore));
        md.loginMember[0].type = 'In-Clinic';
        dispatch(storeMemberDetails(md));
        let path = '/appointmenttype';
        history.push(path);
    };

    const updateAppointmentDetails = (appointmentRequest) => {
        let temp = JSON.parse(JSON.stringify(_appointmentDetails));
        temp.location.specialInstructions = specialInstructions;
        temp.appointmentRequest = appointmentRequest || {};
        dispatch(storeAppointmentDetails(temp));
    };

    const displayMemberNames = () => {
        if (Array.isArray(_appointmentDetails?.currentMember)) {
            let names = [];
            _appointmentDetails?.currentMember.forEach((m) => {
                names.push(m.memberFullname);
            });
            return names.map((n) => (
                <span>
                    {n} <br />
                </span>
            ));
        }
        return _appointmentDetails?.currentMember?.memberFullname;
    };

    const hasMembersSelected = () => {
        if (
            Array.isArray(_appointmentDetails?.currentMember) &&
            _appointmentDetails?.currentMember.length
        ) {
            return true;
        }
        if (
            _appointmentDetails?.currentMember &&
            !Array.isArray(_appointmentDetails?.currentMember) &&
            Object.keys(_appointmentDetails?.currentMember).length
        ) {
            return true;
        }
        return false;
    };

    //To format time in to ###-###-####
    function formatPhoneNumber(value) {
        // if input value is falsy eg if the user deletes the input, then just return
        if (!value) return value;

        // clean the input for any non-digit values.
        const phoneNumber = value.replace(/[^\d]/g, '');

        let temp = JSON.parse(JSON.stringify(_appointmentDetails));
        temp.phoneNumber = phoneNumber;
        dispatch(storeAppointmentDetails(temp));

        // phoneNumberLength is used to know when to apply our formatting for the phone number
        const phoneNumberLength = phoneNumber?.length;

        // we need to return the value with no formatting if its less then four digits
        // this is to avoid weird behavior that occurs if you  format the area code to early
        if (phoneNumberLength < 4) return phoneNumber;

        // if phoneNumberLength is greater than 4 and less the 7 we start to return
        // the formatted number
        if (phoneNumberLength < 7) {
            return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(3)}`;
        }

        // finally, if the phoneNumberLength is greater then seven, we add the last
        // bit of formatting and return it.
        return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(
            3,
            6
        )}-${phoneNumber.slice(6, 10)}`;
    }
    const modelMessage = _memberStore?.loginMember[0].type === 'House Call' ? 'house call request' : 'appointment';
    return (
        <Container className="padding-bottom-large edit-appointment margin-top-large">
            {/* Breadcrumb */}
            <BreadCrumb />
            <Row className="section-header inner-header">
                {' '}
                {/*Section Heading*/}
                <Col lg="12">
                    <div className="text-center">
                        <div onClick={history.goBack}>
                            <i className="fa-light fa-chevron-left" />
                        </div>
                        <span className="heading mx-auto">{headerTitle}</span>
                    </div>
                </Col>
            </Row>

            <Row className="whitebg">
                <Col>
                    {/* <h4 className="header-text">Review Appointment Request</h4> */}
                    <h4 className="header-text">
                        {`${pGroupId === '2'
                            ? 'Review House Call Details'
                            : !_appointmentDetails?.time?.customapptime
                                ? 'Review Appointment Details'
                                : 'Review Appointment Request'
                            } `}
                    </h4>
                    <div className="box schedule-apt">
                        <Row className="apt-section">
                            <Col>
                                {_memberStore?.loginMember[0].type !=
                                    'House Call' ? (
                                    !_appointmentDetails?.time
                                        ?.customapptime ? (
                                        <p className="headfont">
                                            Appointment for:{' '}
                                        </p>
                                    ) : (
                                        <p className="headfont">
                                            Appointment Request for:{' '}
                                        </p>
                                    )
                                ) : (
                                    <p className="headfont">Request for: </p>
                                )}
                                <span>
                                    {selectedCovidCareOption.groupName ||
                                        headerTitle}
                                </span>
                            </Col>
                        </Row>

                        <Row className="apt-section">
                            <Col>
                                <p className="headfont">
                                    {Array.isArray(
                                        _appointmentDetails.currentMember
                                    ) &&
                                        _appointmentDetails.currentMember.length > 1
                                        ? 'Members attending appointment:'
                                        : 'Member attending appointment:'}
                                </p>

                                <div className="select-dropdown">
                                    {/* {hasMembersSelected() ? ( */}
                                    <>
                                        <SelectMember
                                            onSelectionChanged={() =>
                                                setEditMemberMode(false)
                                            }
                                        ></SelectMember>
                                    </>
                                    {/* ) : (
                                        ''
                                    )} */}
                                </div>
                                {/* {!editMemberMode ? (
                                    <Link
                                        className="edit"
                                        onClick={(e) => {
                                            setEditMemberMode(true);
                                            e.preventDefault();
                                        }}
                                    >
                                        Edit
                                    </Link>
                                ) : (
                                    ''
                                )} */}
                            </Col>
                        </Row>
                        <Row className="apt-section">
                            <Col>
                                <p className="headfont">{`${pGroupId === '2'
                                    ? 'House Call Address'
                                    : 'Clinic, Date & Time'
                                    } `}</p>
                                <div className="apt-addr">
                                    {_memberStore?.loginMember[0].type !=
                                        'House Call' ? (
                                        <>
                                            <span className="date link">
                                                {
                                                    _appointmentDetails
                                                        ?.location?.name
                                                }
                                            </span>
                                            <br />
                                            <span>
                                                {
                                                    _appointmentDetails
                                                        ?.location?.address
                                                }
                                            </span>
                                            <br />
                                            <span>
                                                {_appointmentDetails?.location
                                                    ?.city +
                                                    ', ' +
                                                    _appointmentDetails
                                                        ?.location?.state +
                                                    ' ' +
                                                    _appointmentDetails
                                                        ?.location?.zip}
                                            </span>
                                            <br />
                                        </>
                                    ) : (
                                        _appointmentDetails?.location
                                            ?.memberaddress
                                    )}
                                </div>
                                {_memberStore?.loginMember[0].type !=
                                    'House Call' ? (
                                    <div
                                        className="edit link"
                                        onClick={history.goBack}
                                    >
                                        Edit
                                    </div>
                                ) : (
                                    <div
                                        className="edit link"
                                        onClick={() => history.go(-2)}
                                    >
                                        Edit
                                    </div>
                                )}

                                {_memberStore?.loginMember[0].type !=
                                    'House Call' ? (
                                    <p className="date link mt-2">
                                        {_memberStore?.loginMember[0].type !=
                                            'House Call'
                                            ? selectedDate
                                                ? moment(selectedDate).format(
                                                    'llll'
                                                )
                                                : ''
                                            : selectedDate
                                                ? moment(selectedDate).format(
                                                    'ddd, MMM DD, YYYY'
                                                ) +
                                                ' ' +
                                                _appointmentDetails.time
                                                    .starttime +
                                                '-' +
                                                _appointmentDetails.time.endtime
                                                : _appointmentDetails.time.time ||
                                                ''}
                                    </p>
                                ) : (
                                    <div
                                        className="edit link"
                                        onClick={() => history.go(-2)}
                                    >
                                        Edit
                                    </div>
                                )}
                            </Col>
                        </Row>

                        {_memberStore?.loginMember[0].type != 'House Call' ? (
                            ''
                        ) : (
                            <Row className="apt-section">
                                <Col>
                                    <p className="headfont">
                                        Special Instructions
                                    </p>
                                    <Input
                                        type="textarea"
                                        placeholder="Enter any special entry instruction here"
                                        value={specialInstructions}
                                        onChange={(e) =>
                                            setSpecialInstructions(
                                                e.target.value
                                            )
                                        }
                                    />
                                </Col>
                            </Row>
                        )}
                        {_memberStore?.loginMember[0].type != 'House Call' ? (
                            ''
                        ) : (
                            <Row className="apt-section">
                                <Col>
                                    <p className="headfont">Phone Number</p>
                                    <Input
                                        type="tel"
                                        name="phoneNumber"
                                        value={phoneNumber}
                                        maxlength="12"
                                        onChange={(e) =>
                                            setPhoneNumber(
                                                formatPhoneNumber(
                                                    e.target.value
                                                )
                                            )
                                        }
                                    />
                                    {/* <span>
                                        {_appointmentDetails.phoneNumber}
                                    </span>
                                    <div
                                        className="edit link"
                                        onClick={history.goBack}
                                    >
                                        Edit
                                    </div> */}
                                </Col>
                            </Row>
                        )}

                        {!_appointmentDetails?.time?.customapptime ? (
                            <Row className="apt-section">
                                <Col>
                                    {_memberStore?.loginMember[0].type !=
                                        'House Call' ? '' : <>
                                        <p className="headfont">Preferred Time</p>
                                        <p className="date link">
                                            {_memberStore?.loginMember[0].type !=
                                                'House Call'
                                                ? selectedDate
                                                    ? moment(selectedDate).format(
                                                        'llll'
                                                    )
                                                    : ''
                                                : selectedDate
                                                    ? moment(selectedDate).format(
                                                        'ddd, MMM DD, YYYY'
                                                    ) +
                                                    ' ' +
                                                    _appointmentDetails.time
                                                        .starttime +
                                                    ' - ' +
                                                    _appointmentDetails.time.endtime
                                                    : _appointmentDetails.time.time ||
                                                    ''}
                                        </p>



                                        <div
                                            className="edit link"
                                            onClick={history.goBack}
                                        >
                                            Edit
                                        </div>
                                    </>
                                    }
                                </Col>
                            </Row>
                        ) : (
                            <div>
                                <Row className="apt-section">
                                    <Col>
                                        <div className="font-16">
                                            Requested Alternate Time
                                        </div>
                                        <span className="link date">
                                            {
                                                _appointmentDetails?.time
                                                    ?.customAppTimeComments
                                            }
                                        </span>
                                    </Col>
                                </Row>
                            </div>
                        )}
                        <Row className="fixed-btm">
                            {_memberStore?.loginMember[0].type !=
                                'House Call' ? (
                                <Col className="button-group inclinic-btn">
                                    {loading ? (
                                        <div className="working mt-3"></div>
                                    ) : (
                                        <>
                                            <div
                                                className="button-action btn-border"
                                                onClick={() =>
                                                    cancelAppointmentDetails()
                                                }
                                            >
                                                Cancel
                                            </div>
                                            {hasMembersSelected() && !impersonation.isImpersonating ? (
                                                <div
                                                    disabled={isBtnEnabled}
                                                    className="button-action"
                                                    onClick={onUpdate}
                                                >
                                                    {_appointmentDetails?.time?.customapptime ? 'submit request' : 'create appointment'}
                                                </div>
                                            ) : (
                                                <div className="button-action btn-disable">
                                                    create appointment
                                                </div>
                                            )}
                                        </>
                                    )}
                                </Col>
                            ) : (
                                <Col className="button-group">
                                    {loading ? (
                                        <div className="working mt-3"></div>
                                    ) : (
                                        <>
                                            <div
                                                className="button-action btn-border"
                                                onClick={() =>
                                                    cancelAppointmentDetails()
                                                }
                                            >
                                                Cancel
                                            </div>
                                            {hasMembersSelected() &&
                                                phoneNumber?.length === 12 ? (
                                                <div
                                                    disabled={isBtnEnabled || impersonation.isImpersonating}
                                                    className="button-action "
                                                    onClick={createAppointment}
                                                >
                                                    submit request
                                                </div>
                                            ) : (
                                                <div className="button-action btn-disable">
                                                    submit request
                                                </div>
                                            )}
                                        </>
                                    )}
                                </Col>
                            )}
                        </Row>
                        {/* Sticky call footer */}
                        <FooterCallUs />
                    </div>
                </Col>
            </Row>
            <Modal
                modalClassName="solis-modal2 apt-edit"
                isOpen={open}
                backdrop={true}
                centered={true}
            >
                <ModalHeader onClick={handleClose}></ModalHeader>
                <ModalBody>
                    <div className="screening">
                        <h4>Do you want to continue?</h4>
                        {_memberStore?.loginMember[0].type === 'House Call' ? (
                            <p>
                                You have not submitted your request. Are you
                                sure you want to leave this page?
                            </p>
                        ) : (
                            <p>
                                You have not created your appointment. Are you
                                sure you want to leave this page?
                            </p>
                        )}
                    </div>
                </ModalBody>
                <ModalFooter>
                    <div className="button-group">
                        <div
                            className="button-action btn-border "
                            onClick={handleCancel}
                        >
                            YES
                        </div>
                        <div className="button-action" onClick={handleClose}>
                            NO
                        </div>
                    </div>
                </ModalFooter>
            </Modal>
            <Modal
                modalClassName='solis-modal2 apt-edit apt-loading'
                isOpen={showAptLoader}
                backdrop={true}
                centered={true}
            >
                <ModalBody>
                    <div className='apt-loading-img'>
                        <img
                            src='/images/appointment-webtechops.svg'
                            alt='appointment-webtechops'
                            className='svg-appointment-loading'
                        />
                    </div>
                    <div className='apt-loading-body'>
                        <h4>Creating your {modelMessage}</h4>
                        <p className='apt-loading-txt'>
                            <span>This might take a minute.</span>
                            <br />
                            <span>Thank you for your patience.</span>
                        </p>
                    </div>
                    <div id="loaders" className="divShow spinner">
                        <div className="global-spinner">
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                        </div>
                    </div>
                </ModalBody>
            </Modal>
            <Modal
                modalClassName='solis-modal2 apt-edit apt-loading'
                isOpen={aptTimeUnavailable}
                backdrop={true}
                centered={true}
            >
                <ModalBody>
                    <div className='apt-unavailable-img'>
                        <i className="fa-light fa-calendar-check"></i>
                    </div>
                    <div>
                        <p className='apt-loading-txt'>
                            <span className='apt-unavailable-txt'>The time you selected for your appointment is no longer available.</span>
                            <br />
                            <span>Please try again.</span>
                        </p>
                    </div>
                    <div
                        className="button-action btn-primary apt-unavailable-btn"
                        onClick={() => {
                            setAptTimeUnavailable(false)
                            let path = `/changeLocationTime/${pGroupId}/${groupId2}/${groupId}`;
                            history.push(path);
                        }}
                    >
                        OKAY
                    </div>
                </ModalBody>
            </Modal>
        </Container>
    );
}

export default EditAppointment;

/**
 * @author RamaTulasi <rama.tulasi@sollishealth.com>
 */
import { MDBDataTable } from 'mdbreact';
import moment from 'moment';
import { useEffect, useState } from 'react';
import Avatar from 'react-avatar';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import BreadCrumb from '../../components/BreadCrumb';
import FooterCallUs from '../../components/FooterCallUs';
import { storeLabResultList } from '../../features/labResultsListSlice';
import { GetLabTestResults, getPatientDetails } from '../../services/RestApi';
import { hideLoader, showLoader } from '../../utilities/Loader';
import { SYSTEM_ERROR_PAGE } from '../../utilities/Constants';
import { checkVersionDiff } from '../../utilities/helper';
import { MemberAvatar } from '../../components';

function LabResults() {
    const history = useHistory();
    const [labResults, setLabResults] = useState([]);
    const [memberDetails, setMemberDetails] = useState({});
    const [loading2, setLoading2] = useState(false);
    const _memberDetails = useSelector((state) => state.memberDetails);
    let { patientDetailsMember } = getPatientDetails(_memberDetails);
    const dispatch = useDispatch();
    const _labResultList = useSelector((state) => state.labResult.labResult);
    

    useEffect(() => {
        showLoader();
        let memberDetails = _memberDetails || {};
        const { memberIds } = getPatientDetails(_memberDetails);
        setMemberDetails(memberDetails);
        let postData = {
            memberIds: memberIds.map(x => x.memberId),
        };
        // setLoading2(true);
        if (!navigator.onLine) {
           // setLoading2(false);
            return setLabResults(_labResultList);
        }
        GetLabTestResults(postData)
            .then((resp) => {
                    checkVersionDiff(dispatch, resp);
                    let list = resp.data.data;
                    list.sort((a, b) => a.labReportDate > b.labReportDate ? -1 : 1);
                    setLabResults(list);
                    dispatch(storeLabResultList(list));
                })
                .catch(err => history.push(SYSTEM_ERROR_PAGE))
                .finally(() => {
                   // setLoading2(false);
                    hideLoader();
            });
    }, [_memberDetails]);

    const data = {
        columns: [
            {
                label: <span>Test</span>,
                field: 'profile',
                sort: 'asc',
            },
            {
                label: <span className="test-title">Type</span>,
                field: 'testtype',
                sort: 'asc',
            },

            {
                label: <span className="date-title">Date</span>,
                field: 'date',
                sort: 'asc',
            },
            {
                label: <span></span>,
                field: 'icon',
                sort: 'asc',
            },
        ],
        rows: labResults?.map((lr, index) => {
            const rowStyling = lr.seenByUser && 'report-viewed'
            return {
            profile: (
                <MemberAvatar
                    key={index}
                    memberId={lr.memberId}
                    size='30'
                    round={true}
                />
            ),
            testtype: lr.labType,
            date: moment(lr.labReportDate).format('MM-DD-YYYY'),
            icon: (
                <>
                    <Link to={`/ labdetails / ${lr.labResultId}`}>
                        <i className="fa-light fa-chevron-right" />
                    </Link>
                </>
            ),

            clickEvent: () => handleClick(lr.labResultId),
            rowClassNames: rowStyling
        }}),
    };
    //It will redirect to view lab result page
    const handleClick = (labResultHeaderID) => {
        let path = `/labdetails/${labResultHeaderID}`;
        history.push(path);
    };

    return (
        <Container className="lab-results margin-top-large">
            {/*Section Heading*/}
            <BreadCrumb titles={['Lab Results']} />
            <h4 className="header-text text-center">Lab Results</h4>

            <Row className="results-table padding-btm">
                <Col>
                    <div className="box">
                        {loading2 ? (
                            <div className="working appointmentsloader"></div>
                        ) : (
                            <MDBDataTable
                                hover
                                paging={false}
                                data={data}
                            />
                        )}
                    </div>
                </Col>
            </Row>
            <FooterCallUs />
        </Container>
    );
}

export default LabResults;
